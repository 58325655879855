Apt.fn.make('filters', {
	/**
	 * Initialize module
	 *
	 * @returns {Object}
	 */
	init: function() {
		var scope = this,
			priv = scope.$private;

		priv.setup();

		scope.refresh($.context('location'));

		$.app.filters.$resume(true);

		priv.bindToggle();

		LS.util.idle([
			priv.bindButtons,
			priv.bindCheck,
			priv.bindMore,
			priv.bindForm,
			function() {
				var $form = $$('filters');

				if (! $form.length) {
					return;
				}

				priv.ranges = Apt.fn['filters.ranges']().init({
					app: 'filters',
					context: $form,
					submit: function() {
						scope.submit();
					}
				});

				LS.util.restrict('[inputmode]', function() {
					priv.mergeData();
				}, $form);
			}
		], priv);

		return scope;
	},

	/**
	 * Merge filters into app
	 *
	 * @param {(Boolean|Object)} [loc]
	 */
	refresh: function(loc) {
		var scope = this,
			priv = scope.$private,
			locator = LS.header.locator;

		priv.mergeData(
			LS.filters.get()
		);

		if (locator && loc !== false) {
			locator.set(loc);
		}

		scope.current = priv.serialize();
	},

	/**
	 * Destroy module
	 *
	 * @private
	 */
	_destruct: function() {
		LS.util.reset('filters', this.$private, [
			'ranges'
		], true);
	}
}, {
	/**
	 * @private
	 */
	_construct: function() {
		var scope = this;

		scope.bounds = {
			price: {
				min: [0, 25000, 50000, 75000, 100000, 250000, 500000],
				max: [25000, 50000, 75000, 100000, 250000, 500000, 1000000]
			},
			size: {
				min: [0, 2, 5, 10, 20, 50, 100],
				max: [2, 5, 10, 20, 50, 100, 250]
			}
		};

		scope.more = [
			'buffer',
			'dom',
			'format',
			'hoa',
			'images',
			'keywords',
			'pending',
			'structure',
			'tag',
			'type',
			'videos'
		];

		scope.model = {
			formats: LS.formats,
			types: LS.types,
			tags: LS.criteria,
			size: {
				bound: 'min',
				options: scope.bounds.size.min
			},
			price: {
				bound: 'min',
				options: scope.bounds.price.min
			},
			reset: 0
		};
	},

	/**
	 * Serialize filters form
	 *
	 * @returns {Object}
	 */
	serialize: function() {
		var data = $$('filters').serialize(true),
			locator = LS.header.locator,
			sort = LS.filters.get('sort');

		if (locator && locator.val()) {
			var value = locator.val();

			if (value.type === 'address') {
				data.center = value.slug.split('+');
				data.zoom = 14;
			} else {
				data.location = value.slug;
			}
		}

		if (sort) {
			data.sort = sort;
		}

		return data;
	},

	/**
	 * Create the filter app
	 */
	setup: function() {
		var scope = this;

		$.app.make('filters', {
			view: 'filters.form',
			target: '$filters',
			model: $.copy(scope.model),
			init: false
		});

		scope.headings = $$('filterHeading').map(function(el) {
			return Number(el.dataset.id);
		});
	},

	/**
	 * Hide filters
	 *
	 * @param {Boolean} [submit=false]
	 */
	hideFilters: function(submit) {
		var scope = this;

		scope.visibleFilter = false;

		$.app.filters.$drop('active');

		$.events.reset('activeFilter');

		LS.util.uncrop();

		document.body.classList.remove('-is-filtered');

		if (submit) {
			scope.$public.submit();
		}
	},

	/**
	 * Merge updated form data into model
	 *
	 * @param {Object} [data]
	 */
	mergeData: function(data) {
		var scope = this;

		if (data) {
			delete data.page;
			delete data.sort;

			data = $.extend(
				$.copy(scope.model), data
			);

			data.reset = LS.filters.count();

			scope.countMore(data);

			$.app.filters.$set(data);

			return;
		}

		data = $.extend({
			format: null,
			tag: null,
			type: null
		}, LS.filters.extract(
			$$('filters').serialize()
		));

		data.reset = LS.filters.count();

		scope.countMore(data);

		$.app.filters.$merge(data);
	}
});